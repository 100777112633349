import React, { useEffect, useState } from "react";
import { Container, Stack, Button, ThemeProvider, Typography, IconButton, Badge, styled, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LogoutIcon from "@mui/icons-material/Logout";
import AssigmentIcon from "@mui/icons-material/Assignment.js";
import SmsIcon from "@mui/icons-material/Sms";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils/SessionFunctions";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { useAtom } from "jotai";
import { adminAtom, checkInterventionsAtom, demoAtom, sideBarDenseAtom, userAtom } from "../../context/atoms/Atoms.Storage";
import { useLocation } from "react-router-dom";
import { getStorageObject } from "../../utils/LocalStorage";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SettingsIcon from "@mui/icons-material/Settings";
import { getPendingInterventionCount } from "../../services/API/Intervention.Services";
import ConfirmModalComponent from "../confirmModal/ConfirmModal.Component";
import zIndex from "@mui/material/styles/zIndex";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useToast } from "../toastComponent/toast.Actions";
import { color } from "framer-motion";
import LockIcon from "@mui/icons-material/Lock";

const Navbar = () => {
  const { openCustomToast } = useToast();

  // STATES AND VARIABLES

  const location = useLocation();
  const [sideBarDense, setSideBarDense] = useAtom(sideBarDenseAtom);
  const [user, setUser] = useAtom(userAtom);
  const [timerId, setTimerId] = useState(null);
  const [demo, setDemo] = useAtom(demoAtom);
  const [userSubscription, setUserSubscription] = useState();
  const [admin, setAdmin] = useAtom(adminAtom);
  const [interventionsCountState, setInterventionsCountState] = useState(0);
  const [logoutModalState, setLogoutModalState] = useState(false);
  const [checkInterventions, setCheckInterventions] = useAtom(checkInterventionsAtom);

  const userData = getStorageObject("userData");
  const repairshopId = getStorageObject("repairShopId");
  const token = getStorageObject("token");

  const widthValue = sideBarDense ? "70px" : "250px";

  useEffect(() => {
    if (userData && userData.id_user_profile === 3) {
      setDemo(true);
    } else {
      setDemo(false);
    }

    if (userData && userData.id_user_subscription && userData.id_user_subscription === 1) {
      setUserSubscription(1);
    } else if (userData && userData.id_user_subscription && userData.id_user_subscription === 2) {
      setUserSubscription(2);
    } else if (userData && userData.id_user_subscription && userData.id_user_subscription === 3) {
      setUserSubscription(3);
    } else {
      openCustomToast("Por favor vuelve a iniciar sesión", "warning", false);
      logout(setUser);
    }

    if (userData && userData.id_user_profile === 1) {
      setAdmin(true);
    } else {
      setAdmin(false);
    }

    getPendingInterventionCount(token)
      .then(({ data: interventionCountData, status, ok }) => {
        setInterventionsCountState(interventionCountData);
      })
      .catch((error) => {});
  }, [checkInterventions]);

  //FUNCTIONS

  const navigate = useNavigate();

  //STYLES

  const CustomButton = styled(Button)(() => ({
    overflow: "visible",
    width: "fit-content",
    padding: `${sideBarDense && "15px 0px"}`,
    textWrap: "nowrap",
    fontWeight: "300",
    color: "white",
    fontSize: "1.2rem",
    textTransform: "none",
    justifyContent: `${sideBarDense ? "center" : "flex-start"}`,
    alignItems: "center",
    gap: "10px",
    transition: "0.1s",
    "&:hover": {
      translate: `5px`,
      // backgroundColor: "rgba(255,255,255,0.15)",
    },
  }));

  const OuterStack = styled(Stack)(() => ({
    transition: "0.1s",
    overflow: "hidden",
    boxShadow: "0 0px 30px 0 #00000017",
    position: "fixed",
    width: widthValue,
    height: "100%",
    backgroundColor: "primary.main",
    borderRadius: "0",
    margin: "0",
    direction: "column",
  }));

  const lockedFunction = {
    color: userData && userData.id_user_subscription === 1 ? "white" : "lightgrey",
    "&:hover": {
      transform: userData && userData.id_user_subscription === 1 ? "translate(0px)" : "translate(-5px)",
    },
  };

  const buttonStyle = {
    overflow: "visible",
    width: "fit-content",
    padding: `${sideBarDense && "15px 0px"}`,
    textWrap: "nowrap",
    fontWeight: "300",
    color: "white",
    fontSize: "1.2rem",
    textTransform: "none",
    justifyContent: `${sideBarDense ? "center" : "flex-start"}`,
    alignItems: "center",
    gap: "10px",
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSideBarDense(true);
      } else {
        setSideBarDense(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const menuOnClick = (_menu) => {
    pendingInterventionsCount();
    navigate(_menu);
  };

  const pendingInterventionsCount = () =>
    getPendingInterventionCount(token, repairshopId)
      .then(({ data: interventionCountData, status, ok }) => {
        setInterventionsCount(interventionCountData);
      })
      .catch((error) => {});

  return (
    <>
      {logoutModalState && (
        <ConfirmModalComponent
          confirmText="¿Deseas cerrar sesión?"
          handleConfirm={() => {
            logout(setUser);
            setLogoutModalState(false);
          }}
          handleCancel={() => setLogoutModalState(false)}
        />
      )}

      {location.pathname === "/intervencion" || location.pathname === "/leads" ? null : (
        <Stack
          id="id-navbar-principal"
          direction="column"
          sx={{
            transition: "0.1s",
            overflow: "hidden",
            boxShadow: "0 0px 30px 0 #00000017",
            position: "fixed",
            width: widthValue,
            height: "100%",
            backgroundColor: "primary.main",
            borderRadius: "0",
            margin: "0",
          }}
        >
          <Stack sx={{ width: "100%", paddingTop: "10px", paddingBottom: "20px", paddingLeft: "1em", alignItems: "flex-start", justifyContent: "flex-end" }}>
            <IconButton sx={{ width: "fit-content" }} onClick={() => setSideBarDense(!sideBarDense)}>
              <DehazeIcon style={{ color: "white" }} />
            </IconButton>
          </Stack>
          {!sideBarDense && (
            <Container sx={{ backgroundColor: "primary.main", display: "flex", height: "70px", paddingBottom: "30px", alignItems: "center", justifyContent: "center" }}>
              <img src={"/img/drivi_logo.svg"} alt="Drivi logo" style={{ width: "50%", filter: "brightness(0) invert(1)" }} />
            </Container>
          )}
          <Container
            sx={{ paddingLeft: "0!important", paddingRight: "0!important", display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}
            paddingLeft={"0!important"}
            display={"flex"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Stack padding={0} width={"fit-content"} direction={"column"}>
              {(admin || getStorageObject("adminSessionToken")) && (
                <CustomButton onClick={() => navigate("/admin")}>
                  <SupervisorAccountIcon />
                  {!sideBarDense && "Administrador"}
                </CustomButton>
              )}
              {!admin && (
                <>
                  <CustomButton onClick={() => navigate("/clientes")}>
                    <PersonIcon /> {!sideBarDense && "Mis clientes"}
                  </CustomButton>
                  <CustomButton onClick={() => menuOnClick("/intervenciones")}>
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      badgeContent={interventionsCountState}
                      sx={{
                        color: "white",
                        "& .MuiBadge-badge": {
                          color: "primary.main",
                          backgroundColor: "white",
                          border: "2px solid primary.main",
                        },
                      }}
                    >
                      <AssigmentIcon />
                    </Badge>
                    {!sideBarDense && "Intervenciones"}
                  </CustomButton>
                  {/* <CustomButton onClick={() => navigate("/mensajes")}>
                <SmsIcon />
                {!sideBarDense && "Mensajes"}
              </CustomButton> */}
                  <Tooltip arrow placement="left" title={userData && userData.id_user_subscription === 1 ? "" : "Esta función sólo está disponible para usuarios Pro"}>
                    <CustomButton
                      sx={{ color: lockedFunction }}
                      onClick={() => {
                        userData && userData.id_user_subscription === 1 ? navigate("/proyeccion") : openCustomToast("Esta función solo está disponible para usuarios Pro", "warning");
                        navigate("/proyeccion");
                      }}
                    >
                      <TrendingUpIcon />
                      {!sideBarDense && "Proyección"}
                      {userData && userData.id_user_subscription !== 1 && <LockIcon sx={{ color: "lightgray" }} />}
                    </CustomButton>
                  </Tooltip>
                  <CustomButton onClick={() => navigate("/taller")}>
                    <CarRepairIcon />
                    {!sideBarDense && "Mi taller"}
                  </CustomButton>{" "}
                </>
              )}
              {/* 
              <CustomButton onClick={() => navigate("/citas")} sx={buttonStyle}>
                <CalendarTodayIcon />
                {!sideBarDense && "Citas"}
              </CustomButton> */}

              <CustomButton onClick={() => navigate("/configuracion")} sx={buttonStyle}>
                <SettingsIcon />
                {!sideBarDense && "Configuración"}
              </CustomButton>

              <CustomButton onClick={() => navigate("/soporte")}>
                <HelpCenterIcon />
                {!sideBarDense && "Soporte"}
              </CustomButton>
              <Button onClick={() => setLogoutModalState(true)} sx={[buttonStyle, { position: "absolute", bottom: "10px" }]}>
                <LogoutIcon />
                {!sideBarDense && "Cerrar sesión"}
              </Button>
            </Stack>
          </Container>
        </Stack>
      )}
    </>
  );
};

export default Navbar;
