import React, { useState, useEffect } from "react";
import { Inject, ScheduleComponent, ViewDirective, ViewsDirective, Day, Week, Month, Agenda } from "@syncfusion/ej2-react-schedule";
import MainContent from "../../components/MainContent.component";
import { registerLicense, loadCldr, L10n } from "@syncfusion/ej2-base";
import { getStorageObject } from "../../utils/LocalStorage.js";
import { Stack } from "@mui/material";
import "./Scheduler.Styles.css";
import * as numberingSystems from "./culture-files/numberingSystems.json";
import * as gregorian from "./culture-files/ca-gregorian.json";
import * as numbers from "./culture-files/numbers.json";
import * as timeZoneNames from "./culture-files/timeZoneNames.json";

registerLicense("Ngo9BigBOggjHTQxAR8/V1NDaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFiW39dcH1XT2BZVUdxVg==");

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load({
  es: {
    schedule: {
      day: "Día",
      week: "Semana",
      workWeek: "Semana de trabajo",
      month: "Mes",
      year: "Año",
      agenda: "Agenda",
      weekAgenda: "Agenda de la semana",
      workWeekAgenda: "Agenda de la semana laboral",
      monthAgenda: "Agenda del mes",
      today: "Hoy",
      noEvents: "No hay eventos",
      emptyContainer: "No hay eventos programados para este día.",
      allDay: "Todo el dia",
      start: "comienzo",
      end: "Final",
      more: "más",
      close: "Cerca",
      cancel: "Cancelar",
      noTitle: "(Sin título)",
      delete: "Eliminar",
      deleteEvent: "Este evento",
      deleteMultipleEvent: "Eliminar múltiples eventos",
      selectedItems: "Artículos seleccionados",
      deleteSeries: "Serie completa",
      edit: "Editar",
      editSeries: "Serie completa",
      editEvent: "Este evento",
      createEvent: "Crear",
      subject: "Tema",
      addTitle: "Añadir título",
      moreDetails: "Más detalles",
      save: "Guardar",
      editContent: "¿Cómo le gustaría cambiar la cita en la serie?",
      deleteContent: "¿Seguro que quieres eliminar este evento?",
      deleteMultipleContent: "¿Estás seguro de que deseas eliminar los eventos seleccionados?",
      newEvent: "Nuevo evento",
      title: "Título",
      location: "Ubicación",
      description: "Descripción",
      timezone: "Zona horaria",
      startTimezone: "Zona horaria de inicio",
      endTimezone: "Zona horaria final",
      repeat: "Repetir",
      saveButton: "Guardar",
      cancelButton: "Cancelar",
      deleteButton: "Eliminar",
      recurrence: "Reaparición",
      wrongPattern: "El patrón de recurrencia no es válido.",
      seriesChangeAlert: "¿Desea cancelar los cambios realizados en instancias específicas de esta serie y volver a vincularlos con toda la serie?",
      createError: "La duración del evento debe ser más corta que la frecuencia con la que ocurre. Acorte la duración o cambie el patrón de recurrencia en el editor de eventos de recurrencia.",
      sameDayAlert: "Dos ocurrencias del mismo evento no pueden ocurrir en el mismo día.",
      editRecurrence: "Editar recurrencia",
      repeats: "Repite",
      alert: "Alerta",
      startEndError: "La fecha de finalización seleccionada ocurre antes de la fecha de inicio.",
      invalidDateError: "El valor de la fecha ingresada no es válido.",
      blockAlert: "Los eventos no se pueden programar dentro del rango de tiempo bloqueado.",
      ok: "Okay",
      yes: "si",
      no: "No",
      occurrence: "Ocurrencia",
      series: "Serie",
      previous: "Anterior",
      next: "próximo",
      timelineDay: "Día de la línea de tiempo",
      timelineWeek: "Semana de la línea de tiempo",
      timelineWorkWeek: "Semana laboral cronológica",
      timelineMonth: "Mes de la línea de tiempo",
      timelineYear: "Cronología Año",
      editFollowingEvent: "Eventos siguientes",
      deleteTitle: "Eliminar evento",
      editTitle: "Editar evento",
      beginFrom: "Comience desde",
      endAt: "Termina en",
      expandAllDaySection: "Expandir-sección-todo-el-día",
      collapseAllDaySection: "Colapsar la sección de todo el día",
    },
    recurrenceeditor: {
      none: "Ninguna",
      daily: "Diario",
      weekly: "Semanal",
      monthly: "Mensual",
      month: "Mes",
      yearly: "Anual",
      never: "Nunca",
      until: "Hasta",
      count: "Contar",
      first: "primero",
      second: "Segundo",
      third: "Tercero",
      fourth: "Cuarto",
      last: "Último",
      repeat: "Repetir",
      repeatEvery: "Repite cada",
      on: "Repetir en",
      end: "Final",
      onDay: "Día",
      days: "Dias)",
      weeks: "Semanas)",
      months: "Meses)",
      years: "Años)",
      every: "cada",
      summaryTimes: "veces)",
      summaryOn: "en",
      summaryUntil: "hasta",
      summaryRepeat: "Repite",
      summaryDay: "dias)",
      summaryWeek: "semanas)",
      summaryMonth: "meses)",
      summaryYear: "años)",
      monthWeek: "Mes Semana",
      monthPosition: "Posición del mes",
      monthExpander: "Expansor de mes",
      yearExpander: "Expansor de año",
      repeatInterval: "Intervalo de repetición",
    },
    calendar: {
      today: "Hoy",
    },
  },
});

const SchedulerPage = () => {
  const [scheduleData, setScheduleData] = useState([
    {
      Id: 1,
      Subject: "Meeting",
      StartTime: new Date(2024, 3, 1, 10, 0),
      EndTime: new Date(2024, 3, 1, 11, 0),
      IsAllDay: false,
    },
    {
      Id: 2,
      Subject: "Meeting",
      StartTime: new Date(2024, 3, 1, 12, 0),
      EndTime: new Date(2024, 3, 1, 13, 0),
      Status: "completed",
      IsAllDay: false,
    },
  ]);

  const onPopupOpen = (args) => {
    if (args.type === "Editor") {
      // Hide the "Repeat" section in the event editor
      const repeatElement = document.querySelector(".e-recurrenceeditor");
      if (repeatElement) {
        repeatElement.style.display = "none";
      }
    }
  };

  useEffect(() => {
    const token = getStorageObject("token");
    const repairshopId = getStorageObject("repairShopId");

    // getByRepairshopIdAppointments(token, repairshopId).then((response) => {
    //   console.log(response);
    // });
  });

  const onActionBegin = (args) => {
    console.log(args);

    if (args.requestType === "eventCreate") {
      const newRecords = args.addedRecords.map((record) => ({
        ...record,
        Id: Math.floor(Math.random() * 1_000_000_000),
      }));
      setScheduleData([...scheduleData, ...newRecords]);
    } else if (args.requestType === "eventChange") {
      const updatedData = scheduleData.map((event) => (event.Id === args.changedRecords[0].Id ? args.changedRecords[0] : event));
      setScheduleData(updatedData);
    } else if (args.requestType === "eventRemove") {
      const filteredData = scheduleData.filter((event) => event.Id !== args.deletedRecords[0].Id);
      setScheduleData(filteredData);
    }
  };

  return (
    <MainContent>
      <Stack sx={{ marginTop: "40px" }}>
        <ScheduleComponent onPopupOpen={onPopupOpen} cssClass="custom-schedule" locale="es" eventSettings={{ dataSource: scheduleData }} actionBegin={onActionBegin}>
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" />
            <ViewDirective option="Agenda" />
            <ViewDirective option="Month" />
          </ViewsDirective>
          <Inject services={[Day, Week, Month, Agenda]} />
        </ScheduleComponent>
      </Stack>
    </MainContent>
  );
};

export default SchedulerPage;
